<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <!-- <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/paramSetting' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      > -->
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="24">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('systemConfig')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :rules="rules"
                  :model="systemConfig"
                  ref="systemConfig"
                  label-width="150px"
                  class="systemConfig"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="运营商名称" prop="operatorName">
                        <el-input
                          v-model="systemConfig.operatorName"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="运营商地址" prop="operatorAddress">
                        <el-input
                          v-model="systemConfig.operatorAddress"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item
                        label="运营商登录账号"
                        prop="operatorAccount"
                      >
                        <el-input
                          v-model="systemConfig.operatorAccount"
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item
                        label="运营商登录密码"
                        prop="operatorPassword"
                      >
                        <el-input
                          type="password"
                          v-model="systemConfig.operatorPassword"
                          show-password
                          :maxlength="18"
                          placeholder="请输入密码"
                        ></el-input>
                      </el-form-item>
                    </el-col> -->
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="联系人" prop="contacts">
                        <el-input v-model="systemConfig.contacts"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="联系人电话" prop="contactsPhone">
                        <el-input
                          v-model="systemConfig.contactsPhone"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="微信号" prop="email">
                        <el-input v-model="systemConfig.email"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="头像" prop="operatorLogo">
                        <el-upload
                          class="avatar-uploader"
                          :action="$uploadURL"
                          :http-request="uploadPicLogo"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                        >
                          <img
                            v-if="systemConfig.operatorLogo"
                            :src="systemConfig.operatorLogo"
                            class="avatar"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="身份证正面" prop="idCardFrontSide">
                        <el-upload
                          class="avatar-uploader"
                          :action="$uploadURL"
                          :http-request="uploadPicIdCardFront"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                        >
                          <img
                            v-if="systemConfig.idCardFrontSide"
                            :src="systemConfig.idCardFrontSide"
                            class="avatar"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="身份证反面" prop="idCardSecondSide">
                        <el-upload
                          class="avatar-uploader"
                          :action="$uploadURL"
                          :http-request="uploadPicIdCardSecond"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                        >
                          <img
                            v-if="systemConfig.idCardSecondSide"
                            :src="systemConfig.idCardSecondSide"
                            class="avatar"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="审批状态">
                        <el-select
                          v-model="systemConfig.examineStatus"
                          placeholder="已审核"
                          :disabled="true"
                        >
                          <el-option label="未审核" :value="1"></el-option>
                          <el-option label="已审核" :value="2"></el-option>
                          <el-option label="审核拒绝" :value="3"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="10">
                      <el-form-item label="收益比" prop="incomeRatio">
                        <el-input v-model="systemConfig.incomeRatio"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="2"
                      ><div style="margin-top: 5%">%</div></el-col
                    >
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--  多功能 -->
            <OperatorFence
              v-if="systemConfig.examineStatus != 0 && send"
              :operatorCode="markOperatorCode"
            ></OperatorFence>
            <OperatorBouns
              v-if="systemConfig.examineStatus != 0 && send"
              :operatorCode="markOperatorCode"
            ></OperatorBouns>

            <!-- 扩展-->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import OperatorBouns from "@/views/operator/OperatorBouns.vue";
import OperatorFence from "@/views/operator/OperatorFence.vue";
export default {
  components: {
    OperatorBouns,
    OperatorFence
  },
  data() {
    var checkIncomeRatio = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入收益比"));
      } else {
        if (value > 100 || value < 0) {
          callback(new Error("请输入收益比0-100"));
        }
        callback();
      }
    };
    // var checkEmail = (rule, value, callback) => {
    //   const regEmail = /^\w+@\w+(\.\w+)+$/;
    //   if (regEmail.test(value)) {
    //     // 合法邮箱
    //     return callback();
    //   }
    //   callback(new Error("请输入合法邮箱"));
    // };
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error("请输入合法的电话号码"));
    };

    return {
      send: false, //一开始不要渲染下面的组件
      idCardSecondSide: [],
      idCardFrontSide: [],
      rules: {
        operatorName: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        operatorAddress: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        contacts: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        incomeRatio: [
          { required: true, validator: checkIncomeRatio, trigger: "input" }
        ],
        operatorAccount: [
          { required: true, message: "运营商账号不能为空", trigger: "blur" }
        ],
        email: [{ required: true, message: "微信号不能为空", trigger: "blur" }],
        contactsPhone: [
          { required: true, validator: checkMobile, trigger: "input" }
        ]
      },
      operatorLogo: [],
      // 当前页数
      currPage: 1,
      pageSize: 10,
      page: 1,
      limit: 10,
      tabs: ["基本信息", "多功能", "分润明细表"],
      seeThis: 0,
      loading: true,
      markOperatorCode: "",
      id: "",
      systemConfig: {
        operatorName: "",
        operatorAddress: "",
        operatorAccount: "",
        contacts: "",
        contactsPhone: "",
        email: "",
        operatorLogo: "",
        idCardFrontSide: "",
        idCardSecondSide: "",
        examineStatus: 1,
        incomeRatio: ""
      }
    };
  },
  methods: {
    judgeStatus(examineStatus) {
      if (examineStatus === 0) {
        return "未审批";
      } else if (examineStatus === 1) {
        return "已审批";
      } else {
        return "审批拒绝";
      }
    },
    goIndex() {
      this.$router.go(-1);
    },
    submitForm(systemConfig) {
      this.$refs[systemConfig].validate(valid => {
        if (valid) {
          var that = this;
          //   alert()
          // console.log("aaa"+that.systemConfig)
          that.systemConfig.examineStatus.length == 0
            ? (that.systemConfig.examineStatus = 0)
            : (that.systemConfig.examineStatus =
                that.systemConfig.examineStatus);

          that.$http
            .post("/userOperator/save", { ...that.systemConfig })
            .then(function(response) {
              //   console.log("ss"+that.id)
              that.$notify.success({
                title: "提示",
                message: "保存成功",
                showClose: true
              });
              that.$router.push("/operator/Info");
            })
            // .catch(function(error) {
            //   that.$notify.info({
            //     title: "提示",
            //     message: "保存失败",
            //     showClose: true
            //   });
            // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loadBaseInfo() {
      let that = this;
      console.log(that.id);
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/userOperator/list", {
            page: 1,
            limit: 10,
            condition: {
              id: that.id
            }
          })
          .then(function(response) {
            //  console.log('---------')
            if (response.status == 200) {
              that.total = response.data.data.count;
              that.systemConfig = response.data.data.data[0];
              that.idCardFrontSide.push(that.systemConfig.idCardFrontSide);
              that.idCardSecondSide.push(that.systemConfig.idCardSecondSide);
              that.operatorLogo.push(that.systemConfig.operatorLogo);
              // console.log(that.systemConfig.examineStatus);
              // console.log(response.data.data.data[0])
              that.markOperatorCode = that.systemConfig.operatorCode;
              that.send = true;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      //   var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      //   if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
      //     this.seeThis = 0;
      //   }
      //   if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
      //     this.seeThis = 1;
      //   }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
    //   处理图片
    handleAvatarSuccess(res, file) {
      this.card.imgUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      //   const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      //   if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      //   }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 上传图片

    uploadPicLogo(value) {
      const formData = new FormData();
      formData.append("file", value.file);
      // this.$http.post("/upload/uploadFile", formData).then(res => {
      this.$http.post(this.$uploadURL, formData).then(res => {
        console.log("上传图片", res);
        this.systemConfig.operatorLogo = res.data.data.url;
      });
    },
    uploadPicIdCardFront(value) {
      const formData = new FormData();
      formData.append("file", value.file);
      // this.$http.post("/upload/uploadFile", formData).then(res => {
      this.$http.post(this.$uploadURL, formData).then(res => {
        console.log("上传图片", res);
        this.systemConfig.idCardFrontSide = res.data.data.url;
      });
    },
    uploadPicIdCardSecond(value) {
      const formData = new FormData();
      formData.append("file", value.file);
      // this.$http.post("/upload/uploadFile", formData).then(res => {
      this.$http.post(this.$uploadURL, formData).then(res => {
        console.log("上传图片", res);
        this.systemConfig.idCardSecondSide = res.data.data.url;
      });
    }
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  }
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 278px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 278px;
  height: 178px;
  display: block;
}
</style>
